import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/Layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Projects`}</h1>
    <p>{`Throughout my professional and academic career, I have developed a set of engineering and development `}<a parentName="p" {...{
        "href": "/skills"
      }}>{`skills`}</a>{`. I have learned several of these skills in a formal context, and have used personal projects to consolidate and further develop them. Here are some of my personal projects and notable university work:`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://play.google.com/store/apps/details?id=com.cmgcode.bugblocks"
      }}>{`Bug Blocks`}</a></h2>
    <p>{`Bug blocks is an offline React-Native game designed to replicate the experience of debugging.
In this game, users play through a series of puzzles where they must get blocks from one side of a board to another while avoiding obstacles.
Here, the blocks are meant to represent user inputs while the obstacles represent application processes that may include bugs.`}</p>
    <p>{`As the user progresses, they gain stars and can unlock new levels which increase in difficulty to more accurately represent debugging.
Recently deployed to Google Play and pending App Store approval, this app was developed to build on skills in test-driven development, analytics & user acquisition/monetisation.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px",
          "borderRadius": "20px",
          "overflow": "hidden"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/da57e32a8439b5f184bc97de2938d010/00d43/bugblocks.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB9klEQVQozx2SbU/aYBSG+V+Liz/GPyD+GV0ys8QvZk506AenoptvMTMylGjXQlton1YKtIW+IlCuPeNJTnI+nJf7us9TikMH47WJEDaNRoNq9Yj9ygG6rqNqLb7ufuGh9g3SmNF4zN3dDdkkYFZE3NxeEfgBk0mO3jKIvYzSm+vw1PiDbdt4Xh9Y4LmC5+aLLJwuh1xcXDLJc5I44aR2wnmjRq15yfH5MUmUMJ1Pab/oGD9dSvv7FX6cnqEoCqYcOgocWk+/EVaPOIoIPEEQhvi+T5plHFUq3B4ecF/9TnVvjzfPw5WijI7FeAalebGAIqdZf8C0PEk2w7EdOm2VNPAwDR3HcVBVlVyqXC+X+bCywsfVVdbW1uj1emiaRtfqkr5LZE0V+ENBR3kmDGOKWYRtCaJ4QDAQVA4O+by9zdbWp6W/Ozs7lNfX2ShvsLm5SVEURJLENHXy9xElpdleehOF1tI3V/p3df2Lx8eGxBCy0CRNUtI0wzAMMokdJ7EkiVjM5/x/UZLLPltmUmHXtLG6bwRDC+VVoV6vY2imvJpGf9hDyEJX83HVocwt6WtMEqbovYR+lFJMB0x9k+uzU05llIQQDAa+REzp9/vLjZOR3JwGsrlLS36fNMmW0Wq3iOOYbJBz35lgjhLm+V8W4o6xpiwP9A+WyjrQYi12MQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bug Blocks Screenshot",
            "title": "Bug Blocks Screenshot",
            "src": "/static/da57e32a8439b5f184bc97de2938d010/0a47e/bugblocks.png",
            "srcSet": ["/static/da57e32a8439b5f184bc97de2938d010/8a4e8/bugblocks.png 150w", "/static/da57e32a8439b5f184bc97de2938d010/5a46d/bugblocks.png 300w", "/static/da57e32a8439b5f184bc97de2938d010/0a47e/bugblocks.png 600w", "/static/da57e32a8439b5f184bc97de2938d010/1cfc2/bugblocks.png 900w", "/static/da57e32a8439b5f184bc97de2938d010/00d43/bugblocks.png 1000w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2><a parentName="h2" {...{
        "href": "https://play.google.com/store/apps/details?id=com.cmgcode.regexoff&hl=en"
      }}>{`RegExOff`}</a></h2>
    <p>{`RegExOff is another React-Native based multiplayer game designed to educate and challenge users with regular expressions.
In this game, users can solve or create puzzles where they must define a regular expression that meets several requirements.
RegExOff was deployed to Google Play in 2020 and now has an active user base and 4+ star rating.`}</p>
    <p>{`This app was written to enhance skills in user management, cross-platform & back-end development and most importantly regular expressions!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px",
          "borderRadius": "20px",
          "overflow": "hidden"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4918cb6cbfce8aefbd1fbc9de1991657/a6d36/regexoff.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACY0lEQVQoz1WRTUhUURiG765Fi1ZBm6BdQTtrY6tsU4RBtJZEikoqKpIGSylx7M/EWpRZi9LKghYWMk62CCvbKDg1k05kpJONzjgzjfee+zd3Znw6545IHXj5uOe833ue71xtRB9jIBWmJd5LINJLcPIZA5kQl+d6CM484mL0AdciL+hbHCIwe5urM4+5EOnhUrSP4PM77Knby74jB6mp28+hxjq0UPYjj+eGOTHRRf1oJ6dH79OVeErDl3bOT96l4UMn9W+7eZl8x8Pfg/TPhumZfi3rewL32lm3dQMbq7awftsmNldvR0uIReaWk6REjrSZJ2cZzOZ/8TkVZzqfYCqXIJ6dx3ZdysUyrACyqDU8EqapNUDbzQ5agle40d2F5hYKlApFKK26lN+08YTNv8vxXETBolgq4nmevzc9NU3R9f7zacKxMExBQQYXi9IsG1xhYEoVSyU/QFXXsTEMHW/Vp1YsFmMhm0a3BVmxzB9TrwRatr0WqFSQzZZp+iRrkueO3Hfl6I4af6VMNBYlk81iOw5ZPY8hhAx0ZaA0qqaSIlKUKlQGKKkAdaY7JmmRJ2Pp5CTJD32RT5FxJpJxvi4n+Jaf96UJ28SUNAVXvqUi9CqUPpVfK9+KyhAmwrTQDYFXLjMcDnO2uYnWjjaa21oJ3rqOlpd/Vc2uWwJDUliupJUjuPIS1zIr1bYwLduXIjYty3/D/v4n7KzaQc3uGnZVV3OgthYtkvrO4MwYb36O80rW8YU4JcPASqewl9K4mSWW0knyuiHJ5IW245MWvDJDoRCHG+ppPHWSo8ePcabpHH8BE4y21Ld9ofQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "RegExOff Screenshot",
            "title": "RegExOff Screenshot",
            "src": "/static/4918cb6cbfce8aefbd1fbc9de1991657/0a47e/regexoff.png",
            "srcSet": ["/static/4918cb6cbfce8aefbd1fbc9de1991657/8a4e8/regexoff.png 150w", "/static/4918cb6cbfce8aefbd1fbc9de1991657/5a46d/regexoff.png 300w", "/static/4918cb6cbfce8aefbd1fbc9de1991657/0a47e/regexoff.png 600w", "/static/4918cb6cbfce8aefbd1fbc9de1991657/a6d36/regexoff.png 650w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2><a parentName="h2" {...{
        "href": "https://play.google.com/store/apps/details?id=com.cmgcode.barebonesxml&hl=en_ZA&gl=US"
      }}>{`Barebones XML`}</a></h2>
    <p>{`Barebones XML is a native Android app for viewing and editing local XML files developed to improve remote debugging.
This application provides a simple user interface for exploring a complex text file type and was originally developed to improve debugging a legacy application in the field.`}</p>
    <p>{`The app extended beyond this scope and was deployed to Google Play with now over 1,000 downloads and an active user base.
Originally, the app was undertaken to improve skills in native Android development, error management & deployment procedures!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "497px",
          "borderRadius": "20px",
          "overflow": "hidden"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/374981da06c4b8bb1c80b2d4b462dd0c/15d25/barebonesxml.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAACIElEQVQ4y51RXW8SQRTdX0rCV0gIwf4EHk20H75h1qqk2pS+mD7ogzWNH7SFFlsWbFkIRaIpNirs0l12YT843jtldamJD05ycmfuuffMnLlS8eUultc3cX/9OVafFnFP3sCDQhGvSxXI2ztYo9zKky2Buw8LkIs7eHNwTPktrBW2sUJx9fEmlh89Q+HFK0in523sHVaxf9JApaGi9FHBh6qCdu8rxRr2ylUc1M5QVs7x7uiU4hnan79gd7+C98QfUv6o9gnlkzreHtcg4R/LmU5gXl/DdabwXAe+58KnOPN92NYYlmmIs+s4IBKDoQZpOp3CtieYTG5gz+FQkWGY0DQN4/EYpmnegPbco+sjjEaj35xlWbi8+g7JdV1REICFOHKeL+DiMB9wLDCei0/mD/ip6X8EHYfszGYLTbZtYzgciubAAYM5wzAEJ1xRHYP7JFZ2PQ8jbYh6uYTggiAOBgPxSq7j5tuXhV1xFIIMiz75x9W3vyyzIP+jruvipSzk0QPIjFhc43m+OHs0rJBlmqK/aJkHwEKBpQAs6BHP0zVp0lzPZ85LgZXbCGyF/y8s6NNrxKXzofFZCOI/ly9szxbE2KHU6/XQbDahquoC+v0+ut0uOp0O8aqo6VxciNwlcYqioF6vo9FoCPC+1WpByuVyiEQiSCQSiMfjIsZiMeTzeWQyGUSjUSSTScExstksZFkWeT6nUinBp9Np3Flawi96zbHZ4/8qXAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Barebones XML Screenshot",
            "title": "Barebones XML Screenshot",
            "src": "/static/374981da06c4b8bb1c80b2d4b462dd0c/15d25/barebonesxml.png",
            "srcSet": ["/static/374981da06c4b8bb1c80b2d4b462dd0c/8a4e8/barebonesxml.png 150w", "/static/374981da06c4b8bb1c80b2d4b462dd0c/5a46d/barebonesxml.png 300w", "/static/374981da06c4b8bb1c80b2d4b462dd0c/15d25/barebonesxml.png 497w"],
            "sizes": "(max-width: 497px) 100vw, 497px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2><a parentName="h2" {...{
        "href": "./education"
      }}>{`AR Based BCI Control for Autonomous Agents`}</a></h2>
    <p>{`As part of my undergraduate studies, I undertook an engineering capstone research project to test the performance of an augmented-reality based brain-computer interface control system.`}</p>
    <p>{`In this project, the user wears an augmented reality headset (Microsoft Hololens) and visually focuses on a user interface. Simultaneously, a cap monitors brain activity to determine how the user is interacting with the system and moves a robot accordingly.`}</p>
    <p>{`While this specific combination of tools is not new to the field, this research attempted to investigate performance in a wireless uncontrolled environment. In other words, can this tool be used outside of a lab?`}</p>
    <p>{`Ultimately, the project demonstrated the feasibility of applying the solution in a practical context and was awarded the `}<strong parentName="p">{`UTS-IEEE Capstone Award`}</strong>{` for commercialisation and innovation.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px",
          "borderRadius": "20px",
          "overflow": "hidden"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/49685d542f9ed183b8d69e6ea98b23d6/6c2f2/bci-diagram.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.66666666666669%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAAsSAAALEgHS3X78AAABk0lEQVQ4y6VTy06DQBTtp7lxY+LKlT/Vb/APNKTaxKQ1Jl1UFy6sAVsUlFhAHgUCtMDMUA8MhbapNeoJucncmXPPuXeG1vIfaO3fjqJoOp2mafoXsmmaILuu+ztynueIlmWBOZ/P68y3ZGxnWZauQAjRNE2WZdu2KaVJkiCJiDM7yJ7n6boOqdkKoihKkoQSvu/zPM6gEcbYBhk6hmHEccw3OBaLBdreso1jPNNa7xD26nMcKATDiPWSDwJKFblmCoLQ6/XWlXdO0XEcVNwgw0y3e3XT75NyY4vDLdcyDZlnozA0Ph3LsuMoqpPcNpCzasLIwHbTc1EmZ04Qn92+XdyphGSgkRKgQgvMxH5ipHhnqqq22+3RaFSRMdIXeTIYPhwcHh2fnNrujJWo9LMgtsVX+Tm2xCWL3rWPy05HUZTGtu/NHD+8vh8PHhVajLZwWyijBFY0TV2J0cJ5EAS4asSNex6+J8KYnktkYqVlwyVrBUZTPi08BNw8YkOGWJhQ3QlML5pnbNe08x9/jLz89mG90Bep+9cJDXxo+AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "AR Based BCI Overview",
            "title": "AR Based BCI Overview",
            "src": "/static/49685d542f9ed183b8d69e6ea98b23d6/0a47e/bci-diagram.png",
            "srcSet": ["/static/49685d542f9ed183b8d69e6ea98b23d6/8a4e8/bci-diagram.png 150w", "/static/49685d542f9ed183b8d69e6ea98b23d6/5a46d/bci-diagram.png 300w", "/static/49685d542f9ed183b8d69e6ea98b23d6/0a47e/bci-diagram.png 600w", "/static/49685d542f9ed183b8d69e6ea98b23d6/6c2f2/bci-diagram.png 767w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      